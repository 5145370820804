<template>
  <router-view :key="$route.name"/>
</template>

<script>
export default {

};
</script>

<style>
</style>
